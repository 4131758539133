export default {
    name: "UserMixins",
  
    methods: {
      mapUserType(type) {
        switch (type) {
          case "GRC_user":
            return "Consultant";
  
          case "Customer":
            return "Customer";
  
          case "Vendor":
            return "Third Party";
  
          case "Portal_user":
            return "Portal User";
        }
      },
  
      deleteUser(user_id) {
        return new Promise((resolve, reject) => {
          const options = {
            method: "DELETE",
            headers: { "content-type": "application/x-www-form-urlencoded" },
            url: `${process.env.VUE_APP_ONECSF_API_URL}/users/${user_id}`,
          };
          this.$http(options)
            .then((res) => {
              resolve(res);
            })
            .catch((err) => {
              reject(err);
            });
        });
      },
  
      restoreUser(user_id) {
          return new Promise((resolve, reject) => {
            const options = {
              method: "PATCH",
              headers: { "content-type": "application/x-www-form-urlencoded" },
              url: `${process.env.VUE_APP_ONECSF_API_URL}/users/${user_id}/restorer`,
            };
            this.$http(options)
              .then((res) => {
                resolve(res);
              })
              .catch((err) => {
                reject(err);
              });
          });
        },
  
     
      getUsers(params) {
        const newParams = {};
        Object.keys(params).forEach((key) => {
          if (params[key] && params[key] !== null && params[key] != "") {
            newParams[key] = params[key];
          }
        });
  
        return new Promise((resolve, reject) => {
          const options = {
            method: "GET",
            headers: { "content-type": "application/x-www-form-urlencoded" },
            url: `${process.env.VUE_APP_ONECSF_API_URL}/users`,
            params: newParams,
          };
          this.$http(options)
            .then((res) => {
              resolve(res);
            })
            .catch((err) => {
              reject(err);
            });
        });
      },
    },
  };
  